<style>
.background-bb{
    background-image: url('../assets/a3-pregnancy.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.testimonials {
    background-color: #fefefe;
    background-image: url('https://www.pregnancymiracle.com/video2019/img/header-bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 11px 11px 6px 6px;
    /* box-shadow: 3px 4px 7px 4px #a2a2a2; */
    color: #353535;
    display: block;
    margin-bottom: 7em;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    position: relative;
    top: 3em;
    padding-bottom: 2em;
}

.shadow {
    -moz-box-shadow: 1px 1px 8px 2px #a2a2a2;
    -webkit-box-shadow: 1px 1px 8px 2px #a3a3a3;
    box-shadow: 1px 1px 8px 2px #a2a2a2;
}

.testimonials .header {
    font-size: 3.4vw;
    line-height: 1.2em;
    max-width: 83%;
    position: relative;
    margin: auto;
    text-align: center;
    padding-top: 10px;
    color: #fff;
}

.testimonials-list {
    color: #353535;
    font-size: 15px;
    margin: auto;
    max-width: 100%;
    padding-top: 6%;
    position: relative;
}

.tmn-list {
    background-image: none;
    clear: both;
    display: block;
    float: left;
    height: 100%;
    margin: auto;
    max-width: 44%;
    position: relative;
}

@media screen and (min-width: 400px) and (max-width: 529px) {
    .tmn-list {
        max-width: 92%;
        margin-left: auto;
        margin-right: auto;
    }
}

.tmn-listitem {
    background-image: none;
    list-style-type: none;
    margin: auto auto 1em;
    max-width: 100%;
    position: relative;
    padding-left: 2em;
}

@media screen and (min-width: 400px) and (max-width: 529px) {
    .tmn-listitem {
        padding-left: 0.7em;
        padding-right: 0.7em;
    }
}

.tmn-listitem .image {
    margin-top: 1em;
    margin-bottom: 1em;
    box-sizing: border-box;
    text-align: center;
}

@media screen and (min-width: 400px) and (max-width: 529px) {
    .tmn-listitem .image {
        margin: 3% auto;
        max-width: 60px;
    }
}

.borderbox {
    border: 1px solid #e5d8f4;
    -moz-border-top-left-radius: 0px;
    -moz-border-top-right-radius: 15px;
    -moz-border-bottom-right-radius: 0px;
    -moz-border-bottom-left-radius: 19px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 15px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 19px;
    border-top-left-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 19px;
}

.tmn-listitem img {
    border: 1px solid #B2358C;
    max-height: 133px;
    text-align: center;
}
</style>
<template>
    <div class="background-bb container-fluid" style="height: 100vh; padding-top: 30px;">
        <h2 style="text-align: center; font-family: 'Sora', sans-serif; font-size: 22px; line-height: 1.3em">
            Essa é a técnica 100% natural para que <span style="background-color: #AC2184; color: #fff">seu bebê seja um menino.</span></h2>
        <p>Baseado em:<br>
            <span v-if="this.usuario.mae == 'Sim'">Você já foi mãe.</span><span v-if="this.usuario.mae == 'Não'">Você ainda não foi mãe.</span><br>
            Você tem {{this.usuario.idade}} anos.
        </p>
            
        <div id="vid_66d08219cd646c000b521e8c" style="position:relative;width:100%;padding: 56.25% 0 0;"> <img id="thumb_66d08219cd646c000b521e8c" src="https://images.converteai.net/0a246cdd-c6bd-48cb-b2bf-d2a8c03c2dd5/players/66d08219cd646c000b521e8c/thumbnail.jpg" style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;display:block;"> <div id="backdrop_66d08219cd646c000b521e8c" style="position:absolute;top:0;width:100%;height:100%;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);"></div> </div>        
        <!--<div class="popup-pitch row">
            <div class="col-4"></div>
            <div class="col-md-4 col-sm-12" style="background-color: white">
                <div class="col-12"
                    style="display: flex;justify-content: center;flex-direction: column;flex-wrap: nowrap;align-items: center; margin-top: 10px">
                    <span
                        style="text-align: center; color: #f00; font-family: 'Sora', sans-serif; font-weight: 700; font-size: 17px; margin-top: 0px">OFERTA
                        RELÂMPAGO</span>
                    <span
                        style="text-align: center; color: #f00; font-family: 'Sora', sans-serif; font-weight: 700; font-size: 17px">De:
                        R$ 2.497<br>Por Apenas: 12x de 24,80</span>
                    <a href="https://pay.kiwify.com.br/2Nsoc9I"
                        style="background-color: #00b200; padding: 15px 0px 15px 0px; margin-top: 20px; width: 270px; font-family: 'Sora', sans-serif; color: white; font-weight: 700; border-radius: 23px; font-size: 17px; text-align: center; border: none">COMPRAR
                        AGORA<br> (ENQUANTO É TEMPO)</a>
                    <img src="../assets/selo.png" style="width: 250px; margin-top: 20px">

                    <div class="testimonials shadow">
                        <h2 class="header"><strong>Mulheres Reais. Resultados Reais.</strong> Milhares de mulheres já testaram e validaram nosso método.</h2>
            
                        <div class="testimonials-list">
                            <ul class="tmn-list">
                                <li class="tmn-listitem borderbox">
                                    <div class="image"><img src="../assets/renata.jpg"></div>
            
                                    <div class="excerpt">
                                        <p>"Depois de ter o meu menino, meu sonho era ter uma menina para ter um casal. Conheci o seu aplicativo enquanto estava tentando engravidar novamente. Segui tudo que estava lá dentro, e foi incrível. Consegui realizar um dos meus maiores sonhos, terei um casal lindo. Tudo faz muito sentido e é muito fácil de aplicar. Já recomendei para todas minhas amigas que desejam ser mães. Deus te abençoe muito."</p>
                                        <strong class="user">Renata Lima, 37 anos, com sua bebê Sofia - <span>Porto Alegre</span></strong>
                                    </div>
                                </li>
            
                                <li class="tmn-listitem borderbox">
                                    <div class="image"><img src="../assets/maria.jpg"></div>
            
                                    <div class="excerpt">
                                        <p>"Seu aplicativo é obrigatório para qualquer mulher que deseja engravidar. Eu estava devastada e meu marido também depois de descobrir que aos 38 anos eu deveria considerar a adoção ou doação de óvulos (meu médico disse que eu estava sem óvulos e me deu 4% de chances de conseguir engravidar). Depois pesquisar muito sobre infertilidade e gravidez, eu encontrei o seu aplicativo. Nunca acreditei muito nessas coisas. Mas eu nunca fiquei tão agradecida por estar errada. Depois de alguns meses seguindo o aplicativo, não só consegui engravidar mas também consegui ter um menininho, algo que sempre foi um desejo pessoal meu. Depois dessa gravidez, segui novamente tudo do aplicativo e consegui ter outro menino, agora meu filho mais velho tem um companheiro kkk. Eu recomendo o seu aplicativo de olhos fechados, foi a resposta das minhas orações. Obrigado por tudo que fez por mim, Ana."</p>
                                        <strong class="user">Maria Gabriela, 41 anos. com seu filho mais novo Gabriel - <span>São Paulo</span></strong>
                                    </div>
                                </li>
            
                                <li class="tmn-listitem borderbox">
                                    <div class="image"><img src="../assets/julia.jpg"></div>
            
                                    <div class="excerpt">
                                        <p>"Ana, meu sonho desde mais nova sempre foi ter uma menina. A idade foi chegando e recebi a notícia de que não conseguiria ter filhos e que a opção seria procurar a adoção, foi bem difícil ouvir isso, mas mesmo assim não desisti e fui atrás de pesquisar mais sobre meu caso. Assisti o seu vídeo, e foi algo tão valioso para mim. Segui todas as suas recomendações e em menos de 3 meses, finalmente consegui realizar meu sonho de ter uma menina. Não tenho palavras para expressar minha gratidão."</p>
                                        <strong class="user">Júlia Yasmin, 43 anos, com sua bebê Letícia - <span>Campinas</span></strong>
                                    </div>
                                </li>
            
                                <li class="tmn-listitem borderbox">
                                    <div class="image"><img src="../assets/suzane.jpg"></div>
            
                                    <div class="excerpt">
                                        <p>"Eu e meu marido sempre tivemos um sonho de ter um família grande com 4 ou 5 filhos. Mas era um desejo particular meu ter uma filha menina, vejo algumas mulheres que engravidam várias vezes e sempre nascem meninos. Fiquei meio ansiosa, sabe? Pesquisei para saber se podia fazer algo para aumentar as chances de ter uma menina, mas tudo era muito caro ou feria meus princípios religiosos. Até que assisti o seu vídeo e dei uma chance, aplicamos tudo para ter uma menina e seguimos as dicas para aumentar as chances de gravidez, e foi incrivelmente rápido. Em coisa de 20 dias eu já consegui engravidar, e já bateu aquela ansiedade para saber se realmente daria certo. Estou te enviando essa mensagem após fazer o exame de sexagen fetal, veio uma menina!! Você não sabe como está feliz, realmente funciona muito e já estou ansiosa para recomendar para minhas amigas."</p>
                                        <strong class="user">Suzane Almeida, 28 anos, grávida da sua menina - <span>Belo Horizonte</span></strong>
                                    </div>
                                </li>
            
                            </ul>
                        </div>

                       
                    </div>

                    <span
                    style="text-align: center; color: #f00; font-family: 'Sora', sans-serif; font-weight: 700; font-size: 17px; margin-top: 0px">OFERTA
                    RELÂMPAGO</span>
                    <span
                        style="text-align: center; color: #f00; font-family: 'Sora', sans-serif; font-weight: 700; font-size: 17px">De:
                        R$ 2.497<br>Por Apenas: 12x de 24,80</span>
                    <a href="https://pay.kiwify.com.br/2Nsoc9I"
                        style="background-color: #00b200; padding: 15px 0px 15px 0px; margin-top: 20px; width: 270px; font-family: 'Sora', sans-serif; color: white; font-weight: 700; border-radius: 23px; font-size: 17px; text-align: center; border: none">COMPRAR
                        AGORA<br> (ENQUANTO É TEMPO)</a>
                    <img src="../assets/selo.png" style="width: 250px; margin-top: 20px; padding-bottom: 40px;">

                    <img src="../assets/garantia.png" style="width: 250px; margin-top: 20px;">
                    <p style="margin-bottom: 20px; margin-top: 10px; font-family: 'Sora', sans-serif; color: #060606">Faça sua compra agora e você receberá acesso ao Método Escolha Natural do Sexo do Bebê. Sabemos que você gostará muito do conteúdo, mas, se dentro do prazo de 7 dias você decidir que não está 100% satisfeita com o método, envie-nos um e-mail para contato@escolhanaturaldosexodobebe.com solicitando o seu reembolso completo.</p>
                    
                </div>
            </div>
            <div class="col-4"></div>
        </div>-->
    </div>
</template>
<script>
    export default {
        name: "VSLMenino",
        data() {
            return {

            }
        },
        props: ['usuario'],
        mounted() {
            console.log(this.usuario);
            this.loadVideoPlayer();
        },
        methods: {
            loadVideoPlayer() {
                const script = document.createElement("script");
                script.src = "https://scripts.converteai.net/0a246cdd-c6bd-48cb-b2bf-d2a8c03c2dd5/players/66d08219cd646c000b521e8c/player.js";
                script.async = true;
                document.head.appendChild(script);
            },
        }
    };
</script>