<style>
.background-bb{
    background-image: url('../assets/a3-pregnancy.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>
<template>
    <div class="background-bb container-fluid" style="height: 100vh; padding-top: 30px;">
        <h2 style="text-align: center; font-family: 'Sora', sans-serif; font-size: 22px; line-height: 1.3em">
            Essa é a técnica 100% natural para que <span style="background-color: #AC2184; color: #fff">seu bebê seja uma menina.</span></h2>
        <p>Baseado em:<br>
            <span v-if="this.usuario.mae == 'Sim'">Você já foi mãe.</span><span v-if="this.usuario.mae == 'Não'">Você ainda não foi mãe.</span><br>
            Você tem {{this.usuario.idade}} anos.
        </p>
            
        <div id="vid_66d081cc1fe54b000b5059ed" style="position:relative;width:100%;padding: 56.25% 0 0;"> <img id="thumb_66d081cc1fe54b000b5059ed" src="https://images.converteai.net/0a246cdd-c6bd-48cb-b2bf-d2a8c03c2dd5/players/66d081cc1fe54b000b5059ed/thumbnail.jpg" style="position:absolute;top:0;left:0;width:100%;height:100%;object-fit:cover;display:block;"> <div id="backdrop_66d081cc1fe54b000b5059ed" style="position:absolute;top:0;width:100%;height:100%;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);"></div> </div>

    </div>
</template>
<script>
    export default {
        name: "VSLMenino",
        data() {
            return {

            }
        },
        props: ['usuario'],
        mounted() {
            console.log(this.usuario);
            this.loadVideoPlayer();
        },
        methods: {
            loadVideoPlayer() {
                const script = document.createElement("script");
                script.src = "https://scripts.converteai.net/0a246cdd-c6bd-48cb-b2bf-d2a8c03c2dd5/players/66d081cc1fe54b000b5059ed/player.js";
                script.async = true;
                document.head.appendChild(script);
            },
        }
    };
</script>