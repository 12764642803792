<style>
.sign {
    max-width: 340px;
    padding: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.card-sign {
    margin-top: 55px;
    width: 90px;
    height: 90px;
    max-width: none !important;
    padding: 4px;
}

.card-sign-img {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-radius: 7px;
    padding: 5px;
}

.card-sign-img:hover {
    background-color: #dcdcdc;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
opacity: 0;
}

.btn-send {
    margin: 10px;
    padding: 10px 20px;
    background-color: #fff;
    color: #060606;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-send:hover {
    background-color: #cfcfcf;
}

.btn-voltar {
    margin: 10px;
    padding: 10px 20px;
    background-color: #549de7;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-voltar:hover {
    background-color: #396ea4;
    color: #fff;
}

.btn-enviar{
    margin: 10px;
    padding: 10px 20px;
    background-color: #4CBB17;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-enviar:hover{
    background-color: #2f760e;
}

.progress-bar {
    background-color: #AC2184 !important;
}
</style>
<template>
    <div style="background-color: #fff4f0; height: 100vh">
        <div class="progress" style="position: fixed; top: 0; width: 100%; height: 5px; z-index: 999;">
            <div class="progress-bar" role="progressbar" :style="{ width: progressPercentage + '%' }" aria-valuenow="progressPercentage" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12" style="display: flex; justify-content: center; flex-direction: column; flex-wrap: nowrap; align-content: center; align-items: center; margin-top: 20px">
                    <h2 style="text-align: center; font-family: 'Sora', sans-serif; font-size: 22px; line-height: 1.3em">
                        Descubra como Escolher o Sexo do seu Bebê através de um Método Americano
                        <span style="background-color: #AC2184; color: #fff">100% natural!</span></h2>
                        <p style="text-align: center; font-family: 'Sora', sans-serif; font-weight: bold">Responda as perguntas abaixo para receber seu método personalizado</p>
                    </div>
            </div>
        </div>
        <div class="container" v-if="currentStep === 'selectGravidez'" style="margin-top: 40px">
            <h4 style="text-align: center; font-family: 'Sora', sans-serif; font-size: 18px; margin-bottom: 0px">Passo
                1: Você já esteve grávida?</h4>
            <div class="row justify-content-center">
                <div>
                    <button class="btn-send" @click="selectGravidez('Sim')">
                        <img style="height: 80px" src="../assets/check.png"><br>
                        Sim
                    </button>
                    <button class="btn-send" @click="selectGravidez('Não')">
                        <img style="height: 80px" src="../assets/x.png"><br>
                        Não
                    </button>
                </div>
            </div>
        </div>

        <div class="container mt-5" v-else-if="currentStep === 'selectSexoBebe'">
            <h4 style="text-align: center; font-family: 'Sora', sans-serif; font-size: 18px; margin-bottom: 0px">Passo
                2: Você deseja ter um bebê de qual sexo?</h4>
            <div class="row justify-content-center">
                <div>
                    <button class="btn-send" @click="selectSexoBebe('Masculino')">
                        <img style="height: 80px" src="../assets/gen/39182903.png"><br>
                        Masculino
                    </button>
                    <button class="btn-send" @click="selectSexoBebe('Feminino')">
                        <img style="height: 80px" src="../assets/gen/27382921.png"><br>
                        Feminino
                    </button>
                </div>
            </div>
        </div>

        <div class="container mt-5" v-else-if="currentStep === 'selectIdade'">
            <h4 style="text-align: center; font-family: 'Sora', sans-serif; font-size: 18px; margin-bottom: 0px">
                Último passo: Quantos anos você tem?</h4>
            <div class="row justify-content-center">
                <div>
                    <input type="text" v-model="idade" class="form-control">
                    <button style="margin-top: 10px" class="btn btn-enviar" @click="submitIdade(idade)">Enviar</button>
                </div>
            </div>
        </div>

        <div class="container mt-5" v-if="currentStep === 'analyzing'">

            <transition name="fade">
                <h4 class="text-center" v-if="!showSecondMessage">Calculando seu método...</h4>
            </transition>
            <transition name="fade">
                <h4 class="text-center" v-if="showSecondMessage">Montando método personalizado...</h4>
            </transition>

            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{ width: progress + '%' }" aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>

        <div class="container" v-if="currentStep !== 'selectGravidez' && currentStep !== 'analyzing'">
            <button class="btn btn-voltar" @click="goBack">Voltar</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "QuizGravidez",
    data() {
        return {
            progress: 0,
            selectedYear: null,
            usuario: { mae: '', sexoBebe: '', idade: ''},
            analyzing: false,
            currentStep: 'selectGravidez',
            firstName: null,
            steps: ['selectGravidez', 'selectSexoBebe', 'selectIdade'],
            steps2: ['selectGravidez', 'selectSexoBebe', 'selectIdade','analyzing'],
            showSecondMessage: false,
        };
    },
    computed: {
        progressPercentage() {
            const totalSteps = this.steps2.length;
            const currentStepIndex = this.steps2.indexOf(this.currentStep);
            return ((currentStepIndex + 1) / totalSteps) * 100;
        }
    },
    methods: {
        selectGravidez(bool){
            this.usuario.mae = bool;
            this.currentStep = 'selectSexoBebe';
        },
        selectSexoBebe(sexoBebe){
            this.usuario.sexoBebe = sexoBebe;
            this.currentStep = 'selectIdade';
        },
        startAnalyzingProgress() {
            this.currentStep = 'analyzing';
            this.showSecondMessage = false;
            this.progress = 0; // Reset do progresso antes de iniciar
            const interval = 20; // Intervalo em ms para incremento da barra
            const totalDuration = 1000; // Duração total da "análise" em ms
            let progressIncrement = interval / totalDuration * 100; // Calcula o incremento necessário

            const progressInterval = setInterval(() => {
                // Simula um atraso maior ao atingir 50% do progresso
                if (this.progress < 50) {
                    this.progress += progressIncrement;
                } else {
                    this.progress += progressIncrement / 2;
                }

                // Verifica se o progresso atingiu ou ultrapassou 100%
                if (this.progress >= 100) {
                    this.progress = 100;
                    clearInterval(progressInterval);
                    //this.currentStep = 'inputFirstName';  Muda a etapa após concluir a "análise"
                    setTimeout(() => {
                        if(this.usuario.sexoBebe == 'Masculino'){
                            this.$router.push({ name: 'VSLMenino', params: { usuario: this.usuario } } );
                        }else{
                            this.$router.push({ name: 'VSLMenina', params: { usuario: this.usuario } });
                        }
                    }, 1700);
                }
            }, interval);
        },
        goBack() {
            // Encontra o índice da etapa atual
            const currentIndex = this.steps.indexOf(this.currentStep);

            // Se não for a primeira etapa, volta uma etapa
            if (currentIndex > 0) {
                this.currentStep = this.steps[currentIndex - 1];
            }
        },
        submitIdade(idade) {
            if(idade == null){
                alert('Preencha a sua idade.');
            }else{
                this.usuario.idade = idade;
                this.startAnalyzingProgress();
            }
            
        },
    }
};
</script>