import Vue from 'vue';
import Router from 'vue-router';
import QuizGravidez from '../components/QuizGravidez.vue';
import VSLMenino from '../components/VSLMenino.vue';
import VSLMenina from '../components/VSLMenina.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'QuizGravidez',
      component: QuizGravidez,
      props: true
    },
    {
        path: '/a143223',
        name: 'VSLMenino',
        component: VSLMenino,
        props: true
    },
    
    {
      path: '/a143223m',
      name: 'VSLMenina',
      component: VSLMenina,
      props: true
  },
],
});
